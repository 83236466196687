<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="invoiceData === undefined">
      <h4 class="alert-heading">Error fetching invoice data</h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link class="alert-link" :to="{ name: 'apps-invoice-list' }"> Invoice List </b-link>
        for other invoices.
      </div>
    </b-alert>
    <b-row v-if="invoiceData" class="invoice-preview" id="invoicePrintout" refs="printFrame">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <img :src="settings.logo" style="max-height: 90px" />
                  <h3 class="text-primary invoice-logo">{{ settings.business_name }}</h3>
                </div>
                <p class="card-text mb-25">
                  {{ settings.location_address1 }}, {{ settings.location_address2 }},<br />
                  {{ settings.location_city }}
                  {{ settings.location_county }}
                  {{ settings.location_country }}
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Invoice
                  <span class="invoice-number"
                    >#{{ invoiceData.id }}
                    <strong v-if="invoiceData && invoiceData.status == -1" class="text-danger">(CANCELLED)</strong>
                    <strong v-if="transaction && transaction.status == 'refund'" class="text-danger">(REFUNDED)</strong>
                  </span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Submitted:</p>
                  <p class="invoice-date">
                    {{ invoiceData.created_at | moment("DD/MM/YYYY HH:mm") }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">Scheduled For:</p>
                  <p class="invoice-date">
                    {{ invoiceData.order_time | moment("DD/MM/YYYY HH:mm") }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Invoice Client & Payment Details -->
          <b-card-body v-if="invoiceData" class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col cols="12" xl="6" class="p-0">
                <h6 class="mb-2">Invoice To:</h6>
                <h6 class="mb-25">
                  {{ invoiceData.name }}
                </h6>
                <p class="card-text mb-25">{{ invoiceData.address_line1 }}, {{ invoiceData.address_line2 }}</p>

                <p class="card-text mb-25">{{ invoiceData.city }},</p>
                <p class="card-text mb-25">{{ invoiceData.county }},</p>
                <p class="card-text mb-25">{{ invoiceData.zip }},</p>
                <p class="card-text mb-25">
                  <a :href="'tel:' + invoiceData.mobile">{{ invoiceData.mobile }}</a>
                </p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col xl="6" cols="12" class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end">
                <div>
                  <h6 class="mb-2">Payment Details:</h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">Method:</td>
                        <td v-if="invoiceData.token != 'cash' && invoiceData.token != 'sage'">Stripe</td>
                        <td v-if="invoiceData.token != 'cash' && invoiceData.token == 'sage'">Sage</td>
                        <td v-else>Cash</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Platform:</td>
                        <td v-if="!invoiceData.origin">Website</td>
                        <td v-else>{{ invoiceData.origin }}</td>
                      </tr>
                      <tr v-if="invoiceData.token != 'cash'">
                        <td class="pr-1">Token:</td>
                        <td>{{ invoiceData.token }}</td>
                      </tr>
                      <tr class="invoice-total-item" v-if="invoiceData.shipping">
                        <td class="invoice-total-title">Type:</td>
                        <td class="invoice-total-amount">Delivery</td>
                      </tr>
                      <tr class="invoice-total-item" v-else>
                        <td class="invoice-total-title">Type:</td>
                        <td class="invoice-total-amount">Collection</td>
                      </tr>
                      <tr v-if="invoiceData.coupon_id">
                        <td class="pr-1">Coupon:</td>
                        <td>{{ getCouponName(invoiceData.coupon_id) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite responsive :items="invoiceData.rows" :fields="['product', 'quantity', 'price', 'sku', 'total']">
            <template #cell(total)="data"> €{{ calcTotalPerItem(data.item) }} </template>
            <template #cell(price)="data"> {{ calcPricePerItem(data.item) }} </template>
            <template #cell(sku)="data">{{ data.item.sku }} </template>
            <template #cell(product)="data">
              {{ data.item.name }}
              <ul>
                <template v-for="(attribiute, attribiuteIndex) in JSON.parse(data.item.extra)">
                  <li class="w100" :key="attribiuteIndex" v-if="attribiute.selected">
                    <template v-if="attribiute.type == 'text'"> {{ attribiute.title }}: {{ attribiute.selected }} </template>
                    <template v-if="attribiute.type == 'select' && attribiute.selected && JSON.parse(attribiute.selected)">
                      <!-- <div>{{ JSON.parse(attribiute.selected).value }}</div> -->

                      <template v-if="JSON.parse(attribiute.selected).value">
                        {{ attribiute.title }}: {{ JSON.parse(attribiute.selected).value }}
                        <i v-if="JSON.parse(attribiute.selected).price">+€{{ parseFloat(JSON.parse(attribiute.selected).price).toFixed(2) }} </i>
                      </template>
                    </template>
                    <template v-if="attribiute.type == 'multi'">
                      {{ attribiute.title }}
                      <ul v-if="attribiute.selected">
                        <template v-for="(multi, multiIndex) in attribiute.selected">
                          <li class="w100" :key="attribiuteIndex + '-' + multiIndex" v-if="multi.value">
                            {{ multi.value }} <i v-if="multi.price">+€{{ parseFloat(multi.price).toFixed(2) }} </i>
                          </li>
                        </template>
                      </ul>
                    </template>
                  </li>
                </template>
              </ul>
            </template>
            <template #cell(total)="data">{{ calcTotalPerItem(data.item) }} </template>
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Persion -->
              <b-col cols="12" md="6" class="mt-md-0 mt-3" order="2" order-md="1" v-if="invoiceData">
                <b-card-text class="mb-0" v-show="invoiceData.comments">
                  <span class="font-weight-bold">Comments:</span><br />
                  <span class="ml-0">{{ invoiceData.comments }}</span>
                </b-card-text>
              </b-col>

              <!-- Col: Total -->
              <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Subtotal:</p>
                    <p class="invoice-total-amount">€{{ invoiceData.sub_total.toFixed(2) }}</p>
                  </div>

                  <div class="invoice-total-item" v-if="invoiceData.shipping">
                    <p class="invoice-total-title">Delivery:</p>
                    <p class="invoice-total-amount">€{{ invoiceData.shipping.toFixed(2) }}</p>
                  </div>
                  <div class="invoice-total-item" v-if="invoiceData.discount">
                    <p class="invoice-total-title">Discount:</p>
                    <p class="invoice-total-amount">- €{{ invoiceData.discount.toFixed(2) }}</p>
                  </div>
                  <div class="invoice-total-item" v-if="invoiceData.service_charge">
                    <p class="invoice-total-title">Service Charge:</p>
                    <p class="invoice-total-amount">€{{ invoiceData.service_charge.toFixed(2) }}</p>
                  </div>
                  <hr class="my-50" />
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">Total:</p>
                    <p class="invoice-total-amount">
                      €{{ (invoiceData.sub_total + invoiceData.shipping + invoiceData.service_charge - invoiceData.discount).toFixed(2) }}
                    </p>
                  </div>
                  <div class="invoice-total-item" v-if="transaction && transaction.status == 'refund'">
                    <p class="invoice-total-title"></p>
                    <p class="invoice-total-amount text-danger">FULL REFUND</p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Note -->
          <!-- <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <span>It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance
              projects. Thank You!</span>
          </b-card-body> -->
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <b-card>
          <!-- Button: DOwnload -->
          <!-- <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="mb-75"
                    block
                    @click="downloadInvoice()">
            Download
          </b-button> -->

          <!-- Button: Print -->
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" class="mb-75" block @click="printInvoice()"> Print </b-button>

          <hr v-if="transaction && transaction.status != 'refund' && invoiceData.token != 'cash'" />

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="warning"
            class="mb-75"
            block
            @click="refund()"
            v-if="transaction && transaction.status != 'refund' && invoiceData.token != 'cash'"
          >
            Refund
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle } from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  props: ["id"],
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,

    Logo,
  },
  methods: {
    isString(data) {
      try {
        let parse = JSON.parse(data);
        return true;
      } catch (e) {
        return false;
      }
    },
    calcTotalPerItem(item) {
      let price = parseInt(item.quantity) * parseFloat(item.price);

      let attribute, multiAttribute;
      for (let x = 0; x < JSON.parse(item.extra).length; x++) {
        attribute = JSON.parse(item.extra)[x];
        if (attribute.type == "select" && attribute.selected && JSON.parse(attribute.selected)) {
          if (JSON.parse(attribute.selected).price) {
            price += parseInt(item.quantity) * parseFloat(JSON.parse(attribute.selected).price);
          }
        }

        if (attribute.type == "multi" && attribute.selected) {
          for (let y = 0; y < attribute.selected.length; y++) {
            multiAttribute = attribute.selected[y];
            if (multiAttribute.price) {
              price += parseInt(item.quantity) * parseFloat(multiAttribute.price);
            }
          }
        }
      }

      return "€" + price.toFixed(2);
    },
    calcPricePerItem(item) {
      let price = parseFloat(item.price);

      let attribute, multiAttribute;
      for (let x = 0; x < JSON.parse(item.extra).length; x++) {
        attribute = JSON.parse(item.extra)[x];
        if (attribute.type == "select" && attribute.selected && JSON.parse(attribute.selected)) {
          if (JSON.parse(attribute.selected).price) {
            price += parseFloat(JSON.parse(attribute.selected).price);
          }
        }

        if (attribute.type == "multi" && attribute.selected) {
          for (let y = 0; y < attribute.selected.length; y++) {
            multiAttribute = attribute.selected[y];
            if (multiAttribute.price) {
              price += parseFloat(multiAttribute.price);
            }
          }
        }
      }

      return "€" + price.toFixed(2);
    },
    getCouponName(coupon_id) {
      if (coupon_id === null) return " - ";
      return _.find(this.coupons, { id: coupon_id }).title;
    },
    refund() {
      if (this.transaction.auto_gen_id) {
        useJwt
          .refund(this.transaction.auto_gen_id)
          .then(() => {
            useJwt
              .get_order_transaction(this.id)
              .then((response) => {
                this.transaction = response.data.data[0];
              })
              .catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Error`,
                    icon: "CropIcon",
                    variant: "danger",
                    text: `We couldn't downlad your order data from the server.`,
                  },
                });
              });
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `You have successfully refunded order.`,
              },
            });
          })
          .catch((e) => {
            console.log(e);
            this.invoiceData = null;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "CropIcon",
                variant: "danger",
                text: `We couldn't refund this order, please try again in few moments.`,
              },
            });
          });
      }
    },
    // downloadInvoice() {
    //   this.$refs.html2Pdf.generatePdf()
    // },
    printInvoice() {
      window.print();
    },
  },
  data() {
    return {
      invoiceData: null,
      coupons: JSON.parse(localStorage.getItem("userCoupons")),
      settings: {},
      transaction: {},
    };
  },
  beforeMount() {
    if (localStorage.getItem("userOrders")) {
      let orders = JSON.parse(localStorage.getItem("userOrders"));
      this.invoiceData = _.find(orders, { id: parseInt(this.id) });
      this.invoiceData.rows = [];
      useJwt
        .get_order(this.id)
        .then((response) => {
          this.invoiceData.rows = response.data.data;
          return this.$forceUpdate();
        })
        .catch(() => {
          this.invoiceData = null;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't downlad your order data from the server.`,
            },
          });
        });
    } else {
      this.$route.push("/takeaway/shop/orders");
    }

    // if (!localStorage.getItem('userSettings')) {
    useJwt
      .get_order_transaction(this.id)
      .then((response) => {
        this.transaction = response.data.data[0];
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your order data from the server.`,
          },
        });
      });
    useJwt
      .get_settings()
      .then((response) => {
        this.settings = {};
        for (let x = 0; x < response.data.data.length; x++) {
          this.settings[response.data.data[x].meta_key] = response.data.data[x].meta_value;
        }
        return localStorage.setItem("userSettings", JSON.stringify(this.generalData));
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your order data from the server.`,
          },
        });
      });
    // }
    if (document.title != "Invoice #" + this.invoiceData.id) {
      document.title = "Invoice #" + this.invoiceData.id;
    }
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-invoice.scss";

.table-responsive thead tr th:last-child,
.table-responsive tbody tr td:last-child {
  text-align: right !important;
}
</style>
